/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { AxiosResponse } from "axios";
import {
    ColumnFilter, DtoListResponseModel, DtoModelResponse,
    GeneralStatus,
    ProfileContractDto,
    SortType,
} from "./data-contracts";
import {ContentType, HttpClient, RequestParams} from "./http-client";

export class ProfileContract<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags ProfileContract
     * @name LoadList
     * @request GET:/ProfileContract/Load
     */
    loadList = (
        query?: {
            /** @format int64 */
            ProviderId?: number;
            /** @format int64 */
            ProfileId?: number;
            /** @format int32 */
            Take?: number;
            /** @format int32 */
            Skip?: number;
            IncludeIds?: number[];
            "OrderBy.Field"?: string;
            "OrderBy.Type"?: SortType;
            SearchTerm?: string;
            ColumnFilters?: ColumnFilter[];
            Status?: GeneralStatus;
            /** @format int64 */
            PracticeId?: number;
            ForDropdown?: boolean;
        },
        params: RequestParams = {},
    ) =>
        this.request<DtoListResponseModel<ProfileContractDto>, any>({
            path: `/ProfileContract/Load`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProfileContract
     * @name PostProfileContract
     * @request POST:/ProfileContract/Add
     */
    postProfileContract = (data: ProfileContractDto, params: RequestParams = {}):Promise<AxiosResponse <DtoModelResponse<ProfileContractDto>>> =>
        this.request<DtoModelResponse<ProfileContractDto>, any>({
            path: `/ProfileContract/Add`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProfileContract
     * @name PostProfileContract
     * @request POST:/ProfileContract/Add
     */
    postPost = (data: ProfileContractDto, params: RequestParams = {}):Promise<AxiosResponse <DtoModelResponse<ProfileContractDto>>> => this.postProfileContract(data, params);
    /**
     * No description
     *
     * @tags ProfileContract
     * @name GetByIdList
     * @request GET:/ProfileContract/GetById
     */
    getByIdList = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<DtoModelResponse<ProfileContractDto>, any>({
            path: `/ProfileContract/GetById`,
            method: "GET",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProfileContract
     * @name DeleteDelete
     * @request DELETE:/ProfileContract/Delete
     */
    deleteDelete = (
        query?: {
            /** @format int64 */
            id?: number;
        },
        params: RequestParams = {},
    ) =>
        this.request<DtoModelResponse<ProfileContractDto>, any>({
            path: `/ProfileContract/Delete`,
            method: "DELETE",
            query: query,
            format: "json",
            ...params,
        });
    /**
     * No description
     *
     * @tags ProfileContract
     * @name UpdateCreate
     * @request POST:/ProfileContract/Update
     */
    updateCreate = (data: ProfileContractDto, params: RequestParams = {}) =>
        this.request<DtoModelResponse<ProfileContractDto>, any>({
            path: `/ProfileContract/Update`,
            method: "POST",
            body: data,
            type: ContentType.Json,
            format: "json",
            ...params,
        });
}
